<template>
    <div v-if="dialog" class="relative z-50">
        <div class="fixed inset-0 bg-black bg-opacity-80"></div>
        <div class="fixed inset-0 overflow-auto">
            <div class="flex items-center justify-center h-full">
                <div class="bg-white rounded-lg p-4 w-full max-w-sm md:max-w-md lg:max-w-md">
                    <div>
                        <div class="font-bold mt-2">
                            <div class="flex justify-center text-center text-lg font-bold">
                                Please click this button to continue payment
                            </div>
                            <div class="flex justify-center">
                                <a
                                    :href="link" target="_blank"
                                    @click="confirm"
                                    class="bg-primary text-white hover:bg-primary-darker disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default font-semibold py-2 px-6 rounded-lg ml-4"
                                >
                                    Continue
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ModalOpenCc',
        data() {
            return {
                dialog: false,
                link: ''
            };
        },

        methods: {
            open({link}) {
                this.link = link;
                this.dialog = true;

                return new Promise((resolve, reject) => {
                    this.resolvePromise = resolve;
                    this.rejectPromise = reject;
                });
            },
            confirm() {
                this.resolvePromise(true);
                this.dialog = false;
            },
            cancel() {
                this.resolvePromise(null);
                this.dialog = false;
            }
        }
    };
</script>
